<script setup lang="ts">
import type { NovaBoxCategoriesItemProps } from './NovaBoxCategoriesItem.types'
import type { CategoryListItem } from '@store'
import { ROUTES } from '@configs'

const props = defineProps<NovaBoxCategoriesItemProps>()
const { gtEvent } = useGoogleTag()
const route = useRoute()
const prevCategory = ref()
const { category } = toRefs(props)
const postStore = usePostStore()
const { feedPosts } = storeToRefs(postStore)
const hasChild = computed(
  () =>
    category.value.lowerCategoryList && category.value.lowerCategoryList.length
)
const isOn = computed(() =>
  route.query.cmtyNttCtgrySn
    ? Number(route.query.cmtyNttCtgrySn) === category.value.cmtyNttCtgrySn
    : category.value.ctgryDfltCeckAt === 'Y'
)
const extend = ref(category.value.ctgryAtmcOpnAt === 'Y')
const isHome = computed(() => {
  const routeName = useGetRouteName()
  return routeName.includes(ROUTES.HOME.name)
})

watch(
  () => feedPosts.value.payload.cmtyNttCtgrySn,
  (cur, prev) => {
    if (cur !== prev) {
      extend.value =
        category.value.lowerCategoryList?.find(
          (category) => category.cmtyNttCtgrySn === cur
        ) !== undefined
    }
  }
)

const handleOnCategoryClick = async (child: CategoryListItem | null) => {
  if (hasChild.value && !child) {
    extend.value = !extend.value
    return
  }
  if (feedPosts.value.loading) {
    return
  }
  if (prevCategory.value !== feedPosts.value.payload.cmtyNttCtgrySn) {
    gtEvent('clickEvent', {
      eventCategory: '클릭',
      eventAction: '카테고리 선택',
      eventLabel: props.category.nttCtgryNm,
      eventSlot: '왼쪽 사이드 메뉴 카테고리',
      eventI18nAddr: props.category.nttCtgryNm,
      eventComponent: 'NovaBoxBoardListNoticeItem',
    })
  }
  const cmtyNttCtgrySn = child
    ? child.cmtyNttCtgrySn
    : category.value.cmtyNttCtgrySn
  prevCategory.value = cmtyNttCtgrySn
  if (!isHome.value) {
    await useNavigations({
      url: ROUTES.FEED.path,
      type: 'replace',
      query: { ...route.query, cmtyNttCtgrySn },
    })
    setTimeout(() => {
      window.scrollTo({ top: 0 })
    }, 300)
  }
}
</script>

<template>
  <div :class="['category-item', { 'has-child': hasChild }, { extend }]">
    <div
      :class="['label-wrap', { on: isOn }]"
      @click="() => handleOnCategoryClick(null)"
    >
      <span class="label">{{ category.nttCtgryNm }}</span>
      <span class="icon-extend">
        <NovaIcon
          :icon="{ type: 'outline', name: 'chev-compact-bottom' }"
          :size="16"
        />
      </span>
    </div>

    <div v-if="hasChild" class="child">
      <NovaBoxCategoriesChildItem
        v-for="child in category.lowerCategoryList"
        :key="child.cmtyNttCtgrySn"
        :category="child"
        @click="() => handleOnCategoryClick(child)"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.category-item {
  display: flex;
  flex-direction: column;

  &.has-child {
    .label-wrap > .icon-extend {
      display: block;
    }
  }

  &.extend {
    .label-wrap > .icon-extend {
      transform: rotate(180deg);
    }

    .child {
      display: flex;
    }
  }

  .label-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 6px 5px;
    text-align: left;
    border-radius: 6px;
    cursor: pointer;
    @include transition(background-color 0.2s);

    &.on {
      background-color: $color-bg-1;
    }

    @media (hover: hover) {
      &:hover {
        background-color: $color-bg-custom-2;
      }
    }

    &:active {
      background-color: $color-bg-custom-3;
    }

    > .label {
      @include text-style($text-body-13-bold);
    }

    > .icon-extend {
      display: none;
      font-size: 0;
      color: $color-text-3;
      @include transition(transform 0.2s);
    }
  }

  .child {
    display: none;
    flex-direction: column;
    gap: 10px;
    padding: 10px 0 10px 20px;
  }
}
</style>
