<script setup lang="ts">
const userStore = useUserStore()
const { t } = useI18n()
const { gtEvent } = useGoogleTag()

const handleOnGoUserHomePage = () => {
  if (userStore.user?.cmtyUserSeCode === 'G') {
    gtEvent('clickEvent', {
      eventCategory: '클릭',
      eventAction: '오른쪽 사이드 메뉴 버튼',
      eventLabel: t('goMyHome'),
      eventSlot: '',
      eventI18nAddr: '',
      eventComponent: 'Button',
    })
    useNavigations({
      external: false,
      url: `/user/@${userStore.user!.userNcnm}`,
    })
  } else if (userStore.user?.cmtyUserSeCode === 'C') {
    gtEvent('clickEvent', {
      eventCategory: '클릭',
      eventAction: '오른쪽 사이드 메뉴 버튼',
      eventLabel: t('goCreatorHome'),
      eventSlot: '',
      eventI18nAddr: '',
      eventComponent: 'Button',
    })
    useNavigations({
      external: false,
      url: `/creator/@${userStore.user.creatorLink}`,
    })
  }
}
</script>

<template>
  <ClientOnly>
    <NovaBoxBase class="box-user">
      <div class="upper">
        <NovaButtonText
          :icon="{ type: 'outline', name: 'user-square' }"
          :label="
            $t(
              userStore.user?.cmtyUserSeCode === 'C'
                ? 'goCreatorHome'
                : 'goMyHome'
            )
          "
          class="go-home-button"
          @click="handleOnGoUserHomePage"
        />
      </div>

      <div class="lower">
        <p class="name">
          {{ userStore.user?.userNcnm }}
        </p>
      </div>

      <div class="my-avatar">
        <NovaPortraitContainer
          :image-url="userStore.user?.userProflUrl"
          :size="'md'"
          :stroke="true"
        />
      </div>
    </NovaBoxBase>
  </ClientOnly>
</template>

<style lang="scss" scoped>
.box-user {
  position: relative;
  flex-shrink: 0;
  flex-direction: column;
  width: 100%;
  padding: 0 !important;
  overflow: hidden;

  > .upper {
    display: flex;
    align-items: flex-end;
    justify-content: right;
    width: 100%;
    height: 34px;
    padding: 0 10px;
    background-color: $color-bg-custom-11;

    .go-home-button {
      padding: 0;
      min-height: 20px;
      font-size: 8px;
      color: $color-text-3;

      :deep(i) {
        font-size: 12px !important;
        width: 12px;
        height: 12px;
      }
    }
  }

  .lower {
    display: flex;
    min-height: 48px;
    padding: 10px 10px 10px 74px;

    .name {
      font-size: 16px;
      font-weight: 700;
      color: $color-black;
      min-height: 30px;
      //@include ellipsis(1);
      word-break: break-all;
    }
  }

  > .title {
    font-size: 16px;
    font-weight: 600;
    color: $color-black;
  }

  .my-avatar {
    position: absolute;
    top: 20px;
    left: 13px;

    :deep(.portrait-container > .inner.stroke) {
      border-width: 4px;
    }
  }
}
</style>
