<script setup lang="ts">
const { t } = useI18n()
const config = useRuntimeConfig()
const userStore = useUserStore()
const myPageStore = useMyPageStore()
const { certifiedKYC } = useKyc()
const unknownLm = computed(() => String(lm.value).replace(/\d/g, '*'))
const isCertificationKyc = computed(() => userStore.user?.kycAt === 'Y')
const lm = computed(() =>
  useFormatThousandsSeparators(myPageStore.rewardCount.rwardSmLM, 2, false)
)
const actionLoading = ref(false)
const { gtEvent } = useGoogleTag()

// 보상 보기 토글
const onToggleShowRewardCount = () => {
  gtEvent('clickEvent', {
    eventCategory: '클릭',
    eventAction: `나의 총 리워드 ${
      myPageStore.showRewards ? '가리기' : '보기'
    }`,
    eventLabel: myPageStore.showRewards ? 'view' : 'view-off',
    eventSlot: '오른쪽 사이드 메뉴 버튼 나의 홈 리워드 눈 아이콘',
    eventI18nAddr: '',
    eventComponent: 'Button',
  })
  myPageStore.showRewards = !myPageStore.showRewards
}

// 보상 사용하기
const onClickUseReward = () => {
  gtEvent('clickEvent', {
    eventCategory: '클릭',
    eventAction: '리워드 사용하기',
    eventLabel: t(
      isCertificationKyc.value ? 'useReward' : 'kycBeforeUseReward'
    ),
    eventSlot: '오른쪽 사이드 메뉴 > 나의 총 리워드 > 리워드 사용하기 버튼',
    eventI18nAddr: t(
      isCertificationKyc.value ? 'useReward' : 'kycBeforeUseReward'
    ),
    eventComponent: 'Button',
  })
  if (isCertificationKyc.value) {
    useNavigations({ external: true, url: config.public.REWARD_URL })
  } else {
    handleOnCertificationKyc()
  }
}

// KYC 인증하기
const handleOnCertificationKyc = async () => {
  try {
    actionLoading.value = true
    await certifiedKYC('reward')
  } catch {
  } finally {
    actionLoading.value = false
  }
}

onMounted(() => {
  myPageStore.fetchRewardCount({
    userCmmnSn: userStore.user!.userCmmnSn,
  })
})
</script>

<template>
  <ClientOnly>
    <NovaBoxBase class="box-my-rewards">
      <div class="inner">
        <div class="header">
          <div class="title-wrap">
            <h4 class="title">{{ $t('myTotalRewards') }}</h4>
          </div>

          <NovaButtonIcon
            v-if="isCertificationKyc"
            :icon="{
              type: 'outline',
              name: myPageStore.showRewards ? 'view' : 'view-off',
            }"
            class="toggle-lm-button"
            @click="onToggleShowRewardCount"
          />
        </div>

        <div class="body">
          <p class="lm">
            <span
              :class="[
                'amount',
                `blur-${
                  isCertificationKyc ? (myPageStore.showRewards ? 0 : 4) : 0
                }`,
                { 'lock-point': !myPageStore.showRewards },
              ]"
            >
              {{ isCertificationKyc ? lm : unknownLm }}
            </span>
            <span class="unit">LM</span>
          </p>
        </div>

        <button
          type="button"
          :class="['use-reward-button', { loading: actionLoading }]"
          @click="onClickUseReward"
        >
          <span class="label">
            {{ t(isCertificationKyc ? 'useReward' : 'kycBeforeUseReward') }}
          </span>
          <NovaIcon
            :icon="{ type: 'outline', name: 'chev-compact-right' }"
            class="icon"
          />
          <NovaLoadingIndicator
            v-if="actionLoading"
            :overlay="true"
            :bg-bright="'light'"
          />
        </button>
      </div>
    </NovaBoxBase>
  </ClientOnly>
</template>

<style lang="scss" scoped>
.box-my-rewards {
  padding: 12px 10px !important;
  background-color: $color-bg-custom-14 !important;

  > .inner {
    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 10px;

      .title-wrap {
        display: flex;
        align-items: center;

        .symbol {
          width: 24px;
          height: 24px;
        }

        .title {
          font-size: 14px;
          font-weight: 700;
          color: $color-white;
        }
      }

      .toggle-lm-button {
        color: $color-white;

        :deep(.icon-wrap) {
          .icon {
            color: $color-white;
          }

          .view-off.icon {
            color: $color-text-5;
          }
        }
      }
    }

    .body {
      margin-top: 24px;

      > .lm {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 12px;
        padding-bottom: 6px;
        font-size: 20px;
        font-weight: 500;
        color: $color-white;
        border-bottom: 1px solid $color-white;

        .amount {
          &.lock-point {
            user-select: none;
          }
        }
      }
    }

    .use-reward-button {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 28px;
      color: $color-black;
      background-color: $color-white;
      border-radius: 13px;
      margin-top: 8px;

      &.loading {
        pointer-events: none;

        > .label,
        > .icon {
          opacity: 0.6;
        }
      }

      > .label {
        font-size: 11px;
      }

      > .icon {
        position: absolute;
        top: 50%;
        right: 8px;
        transform: translateY(-50%);
      }
    }
  }
}
</style>
