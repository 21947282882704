<script setup lang="ts">
const config = useRuntimeConfig()
const { gtEvent } = useGoogleTag()
const { t, locale } = useI18n()
const label = computed(() =>
  t('helpCenter', { appName: config.public.APP_NAME })
)

const notionUrls = {
  ko: config.public.NOTION_URL_KO as string,
  en: config.public.NOTION_URL_EN as string,
  th: config.public.NOTION_URL_TH as string,
} as const

const handleOnClick = () => {
  gtEvent('clickEvent', {
    eventCategory: '클릭',
    eventAction: label.value,
    eventLabel: label.value,
    eventSlot: '오른쪽 사이드 메뉴 버튼',
    eventI18nAddr: '',
    eventComponent: 'Button',
  })
  useNavigations({
    external: true,
    url: notionUrls[locale.value as keyof typeof notionUrls],
  })
}
</script>

<template>
  <ClientOnly>
    <NovaButtonWhiteBox :on-click="handleOnClick">
      <template #default>
        <p>{{ label }}</p>
      </template>
    </NovaButtonWhiteBox>
  </ClientOnly>
</template>
